// extracted by mini-css-extract-plugin
export var approved = "DashboardSuspiciousEmailsIndex__approved__xtWQx";
export var badge = "DashboardSuspiciousEmailsIndex__badge__czRo8";
export var column = "DashboardSuspiciousEmailsIndex__column__HRRoE";
export var controlCell = "DashboardSuspiciousEmailsIndex__controlCell__Kac8m";
export var controlRightAlign = "DashboardSuspiciousEmailsIndex__controlRightAlign__VfJPE";
export var dropdown = "DashboardSuspiciousEmailsIndex__dropdown__LUVHM";
export var dropdownBody = "DashboardSuspiciousEmailsIndex__dropdownBody__T84AI";
export var dropdownBodyLink = "DashboardSuspiciousEmailsIndex__dropdownBodyLink__MTKT1";
export var dropdownBodyRow = "DashboardSuspiciousEmailsIndex__dropdownBodyRow__Oj9n4";
export var fetchError = "DashboardSuspiciousEmailsIndex__fetchError__Wtfl5";
export var flex = "DashboardSuspiciousEmailsIndex__flex__R26zX";
export var flexColumn = "DashboardSuspiciousEmailsIndex__flexColumn__UDSES";
export var gap1 = "DashboardSuspiciousEmailsIndex__gap1__TExjQ";
export var gap2 = "DashboardSuspiciousEmailsIndex__gap2__Vggxn";
export var gap3 = "DashboardSuspiciousEmailsIndex__gap3__HiB8t";
export var gap4 = "DashboardSuspiciousEmailsIndex__gap4__dVyQ7";
export var gap5 = "DashboardSuspiciousEmailsIndex__gap5__UL2Wj";
export var header = "DashboardSuspiciousEmailsIndex__header__l4rkB";
export var headerControls = "DashboardSuspiciousEmailsIndex__headerControls__Tm2Y3";
export var label = "DashboardSuspiciousEmailsIndex__label__IdBCw";
export var layout = "DashboardSuspiciousEmailsIndex__layout__OKwle";
export var pageContainer = "DashboardSuspiciousEmailsIndex__pageContainer__bhv_E";
export var pagination = "DashboardSuspiciousEmailsIndex__pagination__TmrnV";
export var row = "DashboardSuspiciousEmailsIndex__row__HmyvA";
export var searchField = "DashboardSuspiciousEmailsIndex__searchField__mifWb";
export var selected = "DashboardSuspiciousEmailsIndex__selected__wGHA7";
export var spinner = "DashboardSuspiciousEmailsIndex__spinner__xAexv";
export var subHeader = "DashboardSuspiciousEmailsIndex__subHeader__xaKTs";
export var suspiciousEmailMenu = "DashboardSuspiciousEmailsIndex__suspiciousEmailMenu__GQZ3x";
export var table = "DashboardSuspiciousEmailsIndex__table__McE1x";
export var tableLink = "DashboardSuspiciousEmailsIndex__tableLink__VLv0a";
export var title = "DashboardSuspiciousEmailsIndex__title__j0HA0";