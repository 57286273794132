// extracted by mini-css-extract-plugin
export var column = "DashboardSuspiciousEmailsOptions__column__AMGjY";
export var flex = "DashboardSuspiciousEmailsOptions__flex__FfYA5";
export var flexColumn = "DashboardSuspiciousEmailsOptions__flexColumn__zzPTO";
export var gap1 = "DashboardSuspiciousEmailsOptions__gap1__YHWjz";
export var gap2 = "DashboardSuspiciousEmailsOptions__gap2__roPWM";
export var gap3 = "DashboardSuspiciousEmailsOptions__gap3__ONsvW";
export var gap4 = "DashboardSuspiciousEmailsOptions__gap4__WXNS2";
export var gap5 = "DashboardSuspiciousEmailsOptions__gap5__Bk0zJ";
export var popoverBody = "DashboardSuspiciousEmailsOptions__popoverBody__pAJvS";
export var popoverBodyLink = "DashboardSuspiciousEmailsOptions__popoverBodyLink__dxWhq";
export var popoverBodyLinkIcon = "DashboardSuspiciousEmailsOptions__popoverBodyLinkIcon__Vhuqg";
export var row = "DashboardSuspiciousEmailsOptions__row__MV60O";